<script>
import axios from "axios";
import PDFViewer from "../../layouts/pdf-viewer.vue";
import Editor from "@tinymce/tinymce-vue";

export default {
  components: {
    PDFViewer,
    editor: Editor,
  },
  data() {
    return {
      list_soal: [],
      selectedItem: null,
      fileEmbed: null,
      isRichText: false,
      contentRichText: "Loading...",
      intake_type: this.$route.params.intake_type,
      url_backend_pdf_js: process.env.VUE_APP_BACKEND_URL + "/pdf.js/web/viewer.html?file=",
      url_backend_file: process.env.VUE_APP_BACKEND_URL,
    };
  },
  mounted() {
    this.fetchSoal();
  },
  methods: {
    toggleItem(item) {
      if (this.selectedItem === item) {
        this.selectedItem = null;
        this.fileEmbed = null;
        this.isRichText = false;
        this.contentRichText = "Loading...";
      } else {
        this.selectedItem = item;
        if (this.intake_type == "intaketype7") {
          this.fileEmbed = null;
          this.isRichText = true;
          this.contentRichText = item.jawaban;
        } else if (item.tipe == "Rich Text") {
          this.fileEmbed = null;
          this.isRichText = true;
          this.contentRichText = item.rich_text;
        } else if (item.tipe == "Video" || item.tipe == "PPT" || item.tipe == "Excel") {
          this.fileEmbed = item.link_yt;
          this.isRichText = false;
          this.contentRichText = "Loading...";
        } else {
          this.fileEmbed = this.url_backend_pdf_js + this.url_backend_file + item.file;
          this.isRichText = false;
          this.contentRichText = "Loading...";
        }
      }
    },
    fetchSoal() {
      let self = this;
      const apiUrl = process.env.VUE_APP_BACKEND_URL_VERSION + `soal/${this.$route.params.id_jadwal_detail}/${this.$route.params.intake_type}`; // Replace with your API endpoint URL
      axios
        .get(apiUrl)
        .then((response) => {
          var responseData = response.data;
          self.list_soal = responseData.data.data;
          console.log(this.list_soal);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    },
  },
};
</script>
<template>
  <div class="accordion" id="accordionExample">
    <div v-for="(item, key, index) in list_soal" :key="key">
      <div class="accordion-item">
        <h2 class="accordion-header" :id="'heading' + item.id">
          <button
            class="accordion-button"
            type="button"
            @click="toggleItem(item)"
            :class="{ collapsed: selectedItem !== item }"
            :aria-expanded="selectedItem === item"
            :aria-controls="'collapse' + item.id"
          >
            Materi {{ index + 1 }}
          </button>
        </h2>
        <div :id="'collapse' + item.id" class="accordion-collapse collapse" :class="{ show: selectedItem === item }" :aria-labelledby="'heading' + item.id" data-bs-parent="#accordionExample">
          <div class="accordion-body">
            <div v-if="isRichText == true">
              <editor
                api-key="rtwwhvxttwvfpujt6gk5r2tphanjg73g2zwuq7ax98f864g0"
                :init="{
                  height: 500,
                  menubar: false,
                  plugins: [],
                  toolbar: '',
                }"
                :disabled="true"
                v-model="contentRichText"
              />
            </div>
            <div v-else>
              <PDFViewer :pdfUrl="fileEmbed" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
